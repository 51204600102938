import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';
import {version} from "util/config";

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from "./Topbar/index";
import HorizontalDefault from "./Topbar/HorizontalDefault/index";

const FormItem = Form.Item;

class SignIn extends React.Component {
	
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.showAuthLoader();
				this.props.userSignIn(values);
			}
		});
	};

	handleButtonClick(e) {
		message.info('Click on left button.');
		//console.log('click left button', e);
	}

	handleMenuClick(e) {
		message.info('Click on menu item.');
		//console.log('click', e);
	}

	componentDidUpdate() {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			auth.onAuthStateChanged((user) => {
				if (user) {
					this.props.history.push('/');
				} else {
					localStorage.removeItem('user_id');
				}
			});
		} else {
			//console.log('AUTH USER IS NULL');
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		return (
	
		<div className="gx-app-login-wrap">
		
			<HorizontalDefault/>
				<div className="gx-app-login-wrap" id="login-cont">

				<div className="gx-app-login-container">
			
					<div className="gx-app-login-main-content">
				
						<div className="gx-app-login-content">
						<Row><Col span="8"></Col><Col span="16">	<img alt="example" src={require('assets/images/asset_3.png')} /></Col><Col span="4"></Col>
					
						</Row>
						<br></br>		<br></br>		<br></br>
							<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
								<FormItem>
									{getFieldDecorator('email', {
										rules: [
											{
												required: true,
												type: 'email',
												message: 'Por favor introduce un correo electrónico válido'
											}
										]
									})(<Input placeholder="Email" />)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('password', {
										rules: [ { required: true, message: 'Por favor introduce tu contraseña' } ]
									})(<Input type="password" placeholder="Password" />)}
								</FormItem>
								<Link className="gx-login-form-forgot" to="/reset-password">Olvidé mi contraseña</Link>
								<br></br>
								<FormItem>
									{getFieldDecorator('remember', {
										valuePropName: 'checked',
										initialValue: true
									})(
										<Checkbox>
											<IntlMessages id="appModule.iAccept" />
										</Checkbox>
									)}
									
									<span className="gx-signup-form-forgot gx-link">
									<a className="gx-login-form-forgot" href="https://www.gineco-sr.com/aviso" target="_blank">	<IntlMessages id="appModule.termAndCondition" /></a>
									</span>
								</FormItem>

								<FormItem>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										<IntlMessages id="app.userAuth.signIn" />
									</Button>
									<span>
										<IntlMessages id="app.userAuth.or" />
									</span>{' '}
									<Link to="/signup">
										<IntlMessages id="app.userAuth.signUp" />
									</Link>
								</FormItem>
							</Form>

						</div>
			
						{loader ? (
							<div className="gx-loader-view">
								<CircularProgress />
							</div>
						) : null}
						{showMessage ? message.error(alertMessage.toString()) : null}
					    
					</div>
					<span id="version-code">
				{version}</span>
				</div>
		
			</div>
			
		</div>
		);
	}
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedNormalLoginForm);
