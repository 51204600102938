import React, {Component} from 'react';
import ReactPlayer from 'react-player'
import Moment from "moment";
import localization from 'moment/locale/es'
import SweetAlert from 'react-bootstrap-sweetalert';

import {Col, Row, Card, message, Modal} from 'antd';
import CircularProgress from 'components/CircularProgress/index';

import ProductItem from "components/eCommerce/ProductItem";
import {auth, database, functions} from "../../../firebase/firebase";

class VideoGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            userId: "",
            loader: true,
            sendingCertificate: false,
            accesedSerie: {},
            uploadCounter: 0,
            watchTime: 0,
            allVideosCount: 0,
            allVideosFinished: false,
            urlId: 0,
            series: {},
            videos: [],
            myVideos: {},
            currentVideoId: "",
            currentVideo: {},
            currentVideoTime: 0,
            certificateWinner: false,
            certificateWinnerMessage: "",
            currentVideoDuration: 0
        };
        this.getCertificateReady = this
            .getCertificateReady
            .bind(this);
        this.checkSeriesCompletion = this
            .checkSeriesCompletion
            .bind(this);

        this.handleProgress = this
            .handleProgress
            .bind(this);
        this.handleDuration = this
            .handleDuration
            .bind(this);
        this.handleEnded = this
            .handleEnded
            .bind(this);
        this.getUserAccesedSeries = this
            .getUserAccesedSeries
            .bind(this);
        this.getUserInformation = this
            .getUserInformation
            .bind(this);
        this.toggleVideo = this
            .toggleVideo
            .bind(this);
    }

    toggleVideo(e, video, id) {
        var _this = this;

        var valid = true
        var i = 0
        //console.log("ORDER: " + video.order)
        //console.log("ID: " + id)
        //console.log("TITLE: " + video.title)

        while (valid && i < video.order - 1 && i < _this.state.videos.length) {
            //console.log("ACCESING: " + i)
            var tempid = _this
                .state
                .videos[i]
                .id
            if (_this.state.myVideos[tempid] == undefined) {
                valid = false
            } else {
                if (_this.state.myVideos[tempid].completed == false) {
                    valid = false
                }
            }
            i++;
        }
        if (valid || (_this.state.series.video_section_as_evaluation == false) ) {

            //console.log(e)
            //console.log("Current Video :" + video.title)
            //console.log("Current Video obj :" + video)
            //console.log("Current Video link: " + video.link)
            //console.log("Current Video ID: " + id)
            var time = 0
            var watchTime = 0
            if (_this.state.myVideos[id] != undefined && _this.state.myVideos[id] != null) {
                time = _this
                    .state
                    .myVideos[id]
                    .current_time
                watchTime = _this
                    .state
                    .myVideos[id]
                    .watched_time
            }
            //console.log("Current Video TIME: " + time)

            _this.setState(
                {currentVideo: video, currentVideoId: id, watchTime: watchTime, currentVideoTime: time}
            );
            if (!_this.ref.playing) {
                //console.log("playing")
                _this.ref.playing = true
            }
        } else {
            message.error(
                'Debes completar de ver el ' + _this.state.series.videos_watchtime + '% del vid' +
                'eo anterior para acceder a este contenido'
            );
        }
    }

    handleEnded = () => {
        //console.log('onEnded')
        this.setState({playing: this.state.loop})
    }

    checkSeriesCompletion() {
        if(this.state.series.video_section_as_evaluation == true){
        //console.log("CHECKING COMPLETION")
        var _this = this;
        var limit = _this.state.series.videos_watched / 100
        var videosToWatch = Math.ceil(this.state.allVideosCount * limit)
        //console.log("VIDEOS TO WATHC:" + videosToWatch)
        var i = 0
        _this
            .state
            .videos
            .forEach(function (mv) {

                if (_this.state.myVideos[mv.id] != undefined) {
                    if (_this.state.myVideos[mv.id].completed == true) {
                        i++
                    }
                }
            })
        //console.log("FINAL COUNT:" + i)
        if (i >= videosToWatch) {
            if (_this.state.sendingCertificate == false) {
                _this.setState({sendingCertificate: true})
                _this.getCertificateReady()
            }
        } else {

            //console.log("NOT Complete")

        }
    }
    }
    success() {
        Modal.success(
            {title: 'This is a success message', content: 'some messages...some messages...'}
        );
    }

    getCertificateReady() {
        if(this.state.series.video_section_as_evaluation == true){

        var _this = this;
        database
            .collection(
                "users/" + _this.state.userId + "/accesed_series"
            )
            .where("series_id", "==", _this.state.urlId)
            .get()
            .then(function (snapshot) {
                var seriesID = "";
                snapshot.forEach(function (mv) {
                    seriesID = mv.id
                });
                //console.log("SENDING")

                var addMessage = functions.httpsCallable('createCertificate');
                var n = new Date().getTime();
                var folio = (n + '').substr((n + '').length - 5);
                Moment().locale('es', localization)

                var mes = "Felicidades. Ha cumplido con los requisitos del programa " +
                        _this.state.series.title + ". En breve recibirá su constancia en el correo elec" +
                        "trónico con el que se registró."

                _this.setState({certificateWinnerMessage: mes, certificateWinner: true})
                var dateMessage = 'Ciudad de México, ' + Moment(new Date())
                    .locale('es')
                    .format('D [de] MMMM [del]  YYYY');
                addMessage({
                    seriesId: _this.state.urlId,
                    userId: _this.state.userId,
                    folio: folio,
                    date: dateMessage,
                    formalDate: Moment(n).format('DD/MM/YYYY HH:mm:ss'),
                    accessed_series: 'users/' + _this.state.userId + '/accesed_series/' + _this.state.accesedSerie.id
                })
                    .then(function (result) {
                        // Read result of the Cloud Function.
                        var sanitizedMessage = result.data.text;
                    })
                    .catch(function (error) {
                        // Getting the Error details.
                        var code = error.code;
                        var message = error.message;
                        var details = error.details;
                        //console.log("Function error")
                        //console.log(code)
                        //console.log(message)
                        //console.log(details)
                        // ...
                    });
            })
        }
    }

    handleProgress = state => {
        var _this = this;
        var playedSeconds = Math.ceil(state.playedSeconds)

        var watchTime = (_this.state.watchTime)

        if (playedSeconds > 0 && playedSeconds % 2 == 0 && watchTime > 0) {
            var procentage = Math.round(
                (watchTime / _this.state.currentVideoDuration) * 100
            )
            if (procentage >= 98) {
                procentage = 100
            }
            //console.log("PORCENTAGE: " + procentage)
            if (_this.state.myVideos[_this.state.currentVideoId] != undefined && _this.state.myVideos[_this.state.currentVideoId] != null) {
                if (_this.state.myVideos[_this.state.currentVideoId].completed == false) {
                    var completed = false
                    var obj = {
                        "watched_time": watchTime,
                        "current_time": playedSeconds,
                        "porcentage": procentage
                    }
                    var watchtimeLimit = _this.state.series.videos_watchtime
                        if (procentage >= watchtimeLimit) {
                            completed = true
                            obj["completed"] = true
                            obj["completed_on"] = Moment().format("DD/MM/YYYY'T'hh:mm:ss")

                        }
                        database
                        .collection("users")
                        .doc(_this.state.userId)
                        .collection("watched_videos")
                        .doc(_this.state.currentVideoId)
                        .update(obj)
                        .then(() => {
                            //console.log("Document TIME successfully updated!");
                            var myVids = _this.state.myVideos
                            var vid = myVids[_this.state.currentVideoId]
                            vid["watched_time"] = watchTime
                            vid["current_time"] = playedSeconds
                            vid["porcentage"] = procentage
                            vid["completed"] = completed
                            myVids[_this.state.currentVideoId] = vid
                            this.setState({myVideos: myVids})

                        })
                    _this.checkSeriesCompletion()
                } else {
                  database
                  .collection("users")
                  .doc(_this.state.userId)
                  .collection("watched_videos")
                  .doc(_this.state.currentVideoId)
                  .update({
                    watched_time:watchTime,
                    current_time:playedSeconds
                  })
                  .then(() => {
                      //console.log("Document TIME successfully updated!");
                      var myVids = _this.state.myVideos
                      var vid = myVids[_this.state.currentVideoId]
                      vid["watched_time"] = watchTime
                      vid["current_time"] = playedSeconds
                      myVids[_this.state.currentVideoId] = vid
                      this.setState({myVideos: myVids})

                  })
                }
            } else {
                var item = {
                    "started_on": Moment().format("DD/MM/YYYY'T'hh:mm:ss"),
                    "porcentage": procentage,
                    "series_id": _this.state.urlId,
                    "completed": false,
                    "order": _this.state.currentVideo.order,
                    "watched_time": watchTime,
                    "current_time": playedSeconds,
                    "name": _this.state.currentVideo.title
                }

                var ref = database
                    .collection("users")
                    .doc(_this.state.userId)
                    .collection("watched_videos")
                    .doc(_this.state.currentVideoId);
                ref
                    .set(item)
                    .then(() => {
                        //console.log("Document TIME successfully CREATED!");
                        var myVids = _this.state.myVideos
                        myVids[_this.state.currentVideoId] = item
                        this.setState({myVideos: myVids})
                    });
            }
        }

        //console.log("Watchtime: " + watchTime + " Played:" + playedSeconds)
        _this.setState({
            watchTime: watchTime + 1,
            currentVideoTime: playedSeconds
        });

        //console.log('onProgress', state.playedSeconds)
        // We only want to update time slider if we are not currently seeking

        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({currentVideoDuration: duration})
        //console.log(this.state.currentVideo.set_by_system)
        if (this.state.currentVideo.set_by_system == undefined || this.state.currentVideo.set_by_system == false) {
            database
                .collection("videos")
                .doc(this.state.currentVideoId)
                .update({"set_by_system": true, "duration_by_system": Math.round(duration)})
                .then(() => {
                    //console.log("Document duration successfully updated!");
                });

        } else {
            //console.log("Duration already set")
        }
    }

    getUserInformation(doc, videosArr) {
        var _this = this;

        doc
            .ref
            .collection("watched_videos")
            .where("series_id", "==", _this.state.urlId)
            .get()
            .then(function (snapshot) {
                var refDic = {};
                snapshot.forEach(function (mv) {
                    refDic[mv.id] = mv.data()
                });
                _this.setState({myVideos: refDic});
                _this.setState({videos: videosArr});
                var currentId = ""
                var currentIndex = 0
                var i = 0
                var completed = 0
                // CHHECK HERE
                //console.log("VIDEOS")
                //console.log(videosArr)

                while (i < videosArr.length && currentId == "") {
                    //console.log("i:" + i)
                    var dbVideo = videosArr[i]
                    if (refDic[dbVideo.id] != undefined && refDic[dbVideo.id] != null) {
                        if (refDic[dbVideo.id].completed == false && currentId == "") {
                            currentId = dbVideo.id
                            currentIndex = i
                            //console.log("FFFF")
                        }
                    } else {
                        currentId = dbVideo.id
                        currentIndex = i
                        //console.log("SELECTED INDEX:" + i)
                        //console.log(dbVideo.id)
                    }
                    i++
                }

                if (videosArr.length > 0) {
                    if (currentId == "") {
                        currentId = videosArr[0]
                            .id
                            console
                            .log("VIDEOS COMPLETED")
                    }
                }

                if (videosArr[currentIndex] != undefined) {
                    var currentProgress = 0
                    var watchTime = 0
                    //console.log("CURRENT ID:" + currentId)
                    if (currentId != "" && refDic[currentId] != undefined) {
                        currentProgress = refDic[currentId].current_time
                        watchTime = refDic[currentId].watched_time
                    } else {
                        //console.log("CURRENT TIME:" + 0)

                    }
                    //console.log("CURRENT TIME:" + currentProgress)
                    //console.log("Current Video TITLE" + videosArr[currentIndex].data().title)
                    //console.log("Current Video TIME" + currentProgress)
                    _this.setState(
                        {currentVideo: videosArr[currentIndex].data(), currentVideoId: videosArr[currentIndex].id, currentVideoTime: currentProgress, watchTime: watchTime}
                    );
                    setTimeout(() => {
                      _this.setState(
                        {loader:false}
                    );
                    }, (100));
                  
                } else {
                  _this.setState(
                    {loader:false}
                );
                    //console.log("NO Available videos")
                }

            });
    }

    getUserAccesedSeries(doc) {
        var _this = this;
        _this.loader = true
        doc
            .ref
            .collection("accesed_series")
            .where("series_id", "==", _this.state.urlId)
            .get()
            .then(function (snapshot) {
                var refArray = [];
                snapshot.forEach(function (series) {
                    refArray.push(series);
                });

                //console.log(refArray);
                if (refArray.length != 0) {
                    _this.setState({accesedSerie: refArray[0]});
                    database
                        .doc("series/" + _this.state.urlId)
                        .get()
                        .then(function (seriesDoc) {
                            if (seriesDoc.exists) {
                                var series = seriesDoc.data();
                                _this.setState({series: series});
                                //console.log(series)
                                database
                                    .collection("videos")
                                    .where("series", "==", seriesDoc.id)
                                    .get()
                                    .then(function (snapshot) {// start

                                        database
                                        .collection("evaluations")
                                        .where("series", "==", seriesDoc.id)
                                        .get()
                                        .then(function (evSnapshot) {// start

                                        var arr = []
                                        var all = []
                                        snapshot.forEach(function (video) {
                                            var isVideoEvaluation  = false
                                            evSnapshot.forEach(function (ev) {
                                                if(ev.data().video_evaluation == true && ev.data().video == video.id){
                                                    isVideoEvaluation = true
                                                    //console.log("IS VIDEO EVALUATION")
                                                }else{
                                                    //console.log("IS NOT VIDEO EVALUATION")
                                                }
                                            });
                                            if(isVideoEvaluation == false){
                                                all.push(video)
                                                if (video.data().available === true) {
                                                    arr.push(video)
                                                }
                                            }
                                            //console.log("VIDEOS All")
                                            //console.log(arr)
                                        });

                                        _this.setState({allVideosCount: all.length});
                                        var videoArr = arr.sort(function (a, b) {
                                            //change to order
                                            if (a.data().order < b.data().order) {
                                                return -1;
                                            }
                                            if (a.data().order > b.data().order) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                        _this.getUserInformation(doc, videoArr)

                                    });// End
                                });
                            }
                        });
                }
            });
    }

    getVideosInformation(user) {
        //console.log("ALL USER INFO CALLED");
        var _this = this;

        database
            .collection("users")
            .where("email", "==", user.email)
            .get()
            .then(function (querySnapshot) {
                //console.log("ID: " + _this.state.urlId);
                querySnapshot.forEach(function (doc) {
                    _this.setState({user: doc.data(), userId: doc.id});
                    _this.getUserAccesedSeries(doc)
                });
            })
            .catch(function (error) {
                //console.log("Error getVideosInformation :", error);
            });
    }
    ref = player => {
        this.player = player
    }

    componentDidMount() {
        const {urlId} = this.props.match.params;
        var _this = this;

        this.setState({urlId: urlId});
        auth.onAuthStateChanged(user => {
            if (user) {
                _this.getVideosInformation(user);
            }
        });
    }

    render() {
        const {showMessage, loader, alertMessage} = this.props;

        return (
            <div>
                {
                    this.state.loader && (
                        <div className="gx-loader-view">
                            <CircularProgress/>
                        </div>
                    )
                }
                {showMessage && message.error(alertMessage)}
                <SweetAlert
                    show={this.state.certificateWinner}
                    success="success"
                    title={this.state.certificateWinnerMessage}
                    onConfirm={() => {
                        this.setState({certificateWinner: false});
                    }}/>
                <Row>
                    <Col key="ss" xl={16} lg={16} md={16} sm={24} xs={24}>
                        <Card className={`gx-card-metrics`}>
                            <h1 className="gx-product-title">{this.state.series.sponsor}</h1>
                            <h4>{this.state.series.videos_description}</h4>
                            <p>{this.state.series.sponsor_message}</p>
                            <p>{this.state.series.disclamer}</p>
                        </Card>
                    </Col>
                    <Col key="1a" xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Card className={`gx-card-metrics`}>
                            <img src={this.state.series.sponsor_logo}/>
                        </Card>
                    </Col>
                    {
                        (this.state.currentVideo != "")
                            ? <Card className={`gx-card-metrics`}>
                                    <ReactPlayer
                                        width='100%'
                                        ref={this.ref}
                                        playing="playing"
                                        onReady={() => this.player.seekTo(this.state.currentVideoTime)}
                                        height='450px'
                                        onProgress={this.handleProgress}
                                        onEnded={this.handleEnded}
                                        onDuration={this.handleDuration}
                                        url={this.state.currentVideo.link}
                                        controls="controls"/>
                                    <br></br>
                                    <h1 className="gx-product-title">{this.state.currentVideo.title}</h1>
                                </Card>
                            : null
                    }

                    {
                        this
                            .state
                            .videos
                            .map((video, index) => (
                                <Col key={index} xl={4} md={4} sm={24} xs={24}>
                                    <ProductItem
                                        key={index}
                                        grid="grid"
                                        porcentage={(
                                            this.state.myVideos[video.id] == undefined)
                                            ? 0
                                            : this.state.myVideos[video.id].porcentage}
                                        completed={(
                                            this.state.myVideos[video.id] == undefined)
                                            ? false
                                            : this.state.myVideos[video.id].completed}
                                        click={this.toggleVideo}
                                        id={video.id}
                                        showinfo={this.state.series.video_section_as_evaluation}
                                        product={video.data()}/>
                                </Col>
                            ))
                    }
                </Row>
                .
            </div>
        );
    }
}

export default VideoGrid;
