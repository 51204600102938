import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';

class Support extends React.Component {
	componentDidUpdate() {}

	render() {
		return (
			<div className="gx-app-login-wrap">
				<HorizontalDefault />
				<div className="gx-app-login-wrap">
				
				<div className="gx-app-login-container">
					<div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-18 ant-col-xl-19 ant-col-xxl-20">
							<Card title="Soporte">
								<h4>
									Para cualquier aclaración, inconformidad o sugerencia relacionada con su examen
									aplicado en este sistema, le agradeceremos dirigirse a INTERSISTEMAS, S.A. DE C.V.,
									con la Lic. Susana de la Mora Valdés al teléfono 5520 2073, extensión 230 (Lunes a
									viernes de 7:00 a 15:00) o al correo electrónico smora@intersistemas.com.mx
								</h4>
							</Card>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Support;
