import firebase from "firebase";

// Initialize Firebase
const config = {
  databaseURL: "https://ginecosr.firebaseio.com",
  apiKey: "AIzaSyDHr0aO0-CH1B3cfkijfY_xQxil3XOBvc0",
  authDomain: "ginecosr.firebaseapp.com",
  databaseURL: "https://ginecosr.firebaseio.com",
  projectId: "ginecosr",
  storageBucket: "ginecosr.appspot.com",
  messagingSenderId: "236615395937",
  appId: "1:236615395937:web:54c4dfe1a6186a5c0fd820",
  measurementId: "G-46R6Y4XBHB"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.firestore();

export {
  database,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
