import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut, auth} from "appRedux/actions/Auth";

class UserProfile extends Component {

  render() {


    return (

      <div >
        {localStorage.getItem("user_id")?
        <a  id="cerrar"  className="item-menu-custom" onClick={() => this.props.userSignOut()}>Cerrar sesión</a>:null
        }
      </div>

    )

  }
}

export default connect(null, {userSignOut})(UserProfile);
