import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const SocialApps = ({match}) => (
  <Switch>
    {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/button`}/> */}
    <Route path={`${match.url}`} component={
      asyncComponent(() => import('./Profile'))}/>
  </Switch>
);

export default SocialApps;
