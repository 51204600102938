import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';

import {
	hideMessage,
	showAuthLoader,
	hideAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	showAuthMessage,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from "./Topbar/index";
import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import {version} from "util/config";

const FormItem = Form.Item;

class Forgot extends React.Component {
	
	handleSubmit = (e) => {
		e.preventDefault();
		var _this = this;
		this.props.form.validateFields((err, values) => {
			if (!err) {
				//console.log('no error');

				//this.props.showAuthLoader();
		
				//auth.languageCode = 'es';
			
				auth.languageCode = 'es'
				auth.sendPasswordResetEmail(values.email).then(function(user) {
					//_this.props.hideAuthLoader()
					//console.log('sent');

					message.info("¡Correo enviado!");
					
				  }).catch(function(error) {
					  _this.props.hideAuthLoader()
					  //console.log('click left button', error);
					message.error("Ocurrió un error al enviar el correo, asegúrese de que la cuenta este registrada.") 
				  });
			}else{
				//console.log("error")
				//console.log(err);

			}
		});
	};

	handleButtonClick(e) {
		message.info('Click on left button.');
		//console.log('click left button', e);
	}

	handleMenuClick(e) {
		message.info('Click on menu item.');
		//console.log('click', e);
	}

	componentDidUpdate() {
		/*if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			auth.onAuthStateChanged((user) => {
				if (user) {
					this.props.history.push('/');
				} else {
					localStorage.removeItem('user_id');
				}
			});
		} else {
			//console.log('AUTH USER IS NULL');
		}*/
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		return (
		<div className="gx-app-login-wrap">
			<HorizontalDefault/>
				<div className="gx-app-login-wrap" id="login-cont">

				<div className="gx-app-login-container">
				<span id="version-code">
				{version}</span>
					<div className="gx-app-login-main-content">
					
						<div className="gx-app-login-content">
						<Row><Col span="4"></Col><Col span="16">	<img alt="example" src={require('assets/images/asset_3.png')} /></Col><Col span="4"></Col>
					
						</Row>
						<br></br>		<br></br>		<br></br>
							<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
								<FormItem>
									{getFieldDecorator('email', {
										rules: [
											{
												required: true,
												type: 'email',
												message: 'Por favor introduce un correo electrónico válido'
											}
										]
									})(<Input placeholder="Email" />)}
								</FormItem>
														

								<FormItem>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										<IntlMessages id="Enviar link a correo electrónico" />
									</Button>
									
								</FormItem>
							</Form>

						</div>

						{loader ? (
							<div className="gx-loader-view">
								<CircularProgress />
							</div>
						) : null}
						{showMessage ? message.error(alertMessage.toString()) : null}
					</div>
				</div>
			</div>	
		</div>
		);
	}
}

const WrappedNormalForgetForm = Form.create()(Forgot);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader,
	hideAuthLoader,
	showAuthMessage,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedNormalForgetForm);
